/* width */
//.custom-scroll {
$size: 0.42rem;

::-webkit-scrollbar {
  width: $size;
  height: $size;
}

:hover {
  & > ::-webkit-scrollbar-thumb {
    background: #999;
  }

  & > ::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: $size;
}

//}
