@import '../abstracts/variables';

.app-card {
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;

  p,
  h4,
  a,
  span {
    line-height: 1.5;
  }

  &-stroked {
    @extend .app-card;
    border: 1px solid rgba(246, 246, 246, 1); //TODO add to main repo
  }
}
