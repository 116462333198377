@import 'abstracts/variables';

.assets-wrapper.full-screen {
  .mat-drawer-backdrop {
    position: fixed;
  }

  &:not(:hover) {
    nav.assets-navbar.full-screen {
      height: $header-height !important;
      display: flex !important;
      opacity: 1 !important;
    }
  }

  .page {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
  }
}

.page {
  overflow-y: hidden;
  height: calc(100vh - $header-height);
  flex: 1 1 100%;
  font-size: $text-sm;
  padding: 0 2rem 0 2rem !important;

  router-outlet + * {
    overflow: hidden;
  }

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .content-scrollable {
    @extend .content;
    overflow-y: scroll;
  }
}

.extendable {
  flex: 1 1 100%;
  overflow-y: hidden;
  flex-direction: column;
  //display: grid;
  max-height: 100%;
  display: flex;
}
