@import '../abstracts/variables';

$tab-border-radius: calc(2rem / 3);
$tab-border-radius-inner: 0.5rem;
mat-tab-group,
.app-tab-nav-bar {
  //display: block !important;
  max-width: none !important;
  //test
  display: flex !important;
  flex-direction: column;

  .mat-tab-header {
    border-bottom: 0 !important;

    .mat-tab-label-container,
    .mat-tab-link-container {
      display: block !important;
      flex-grow: 0;

      .mat-tab-list {
        .mat-tab-labels,
        .mat-tab-links {
          font-size: 0.9rem;
          width: min-content;
          background-color: #f2f2f2;
          border-radius: $tab-border-radius;
          padding: 0 0.3rem;

          & > * {
            padding: 0 !important;
            margin: 0 !important;
          }

          .mat-tab-label,
          .mat-tab-link {
            font-size: $text-md;
            font-weight: 600 !important;
            opacity: 0.7 !important;
            color: $text-gray;
            width: min-content;

            &:first-child {
              border-radius: 0.83rem 0 0 0.83rem;
            }

            &:last-child {
              border-radius: 0 0.83rem 0.83rem 0;
            }

            height: 3.3rem;

            .mat-tab-label-content {
              width: 100%;
              padding: 0.5rem 1.25rem !important;

              @media screen and (max-width: 1366px) {
                & {
                  width: 95%;
                  padding: 0.333rem 1rem !important;
                  font-size: 1rem;
                }
              }
            }
          }

          .mat-tab-label-active {
            color: $text-dark !important;
            opacity: 1 !important;
            font-weight: bold !important;

            .mat-tab-label-content {
              background-color: #fff;
              box-shadow: rgba(0, 0, 0, 0.16) 0 0.08rem 0.33rem;
              width: 100%;
              padding: 0.5rem 1.25rem !important;
              border-radius: $tab-border-radius-inner;
              transition: all 200ms ease-in-out;

              @media screen and (max-width: 1366px) {
                & {
                  padding: 0.333rem 1rem !important;
                }
              }
            }
          }
        }

        .mat-ink-bar {
          display: none !important;
        }
      }
    }
  }
}

.full-width {
  .mat-tab-header {
    width: 100% !important;
  }

  .mat-tab-label-container {
    display: flex !important;
    flex-grow: 1 !important;
    width: 100% !important;

    .mat-tab-labels {
      width: 100% !important;

      .mat-tab-label {
        width: 100% !important;
      }
    }
  }
}

.tab-primary .mat-tab-label-active {
  .mat-tab-label-content {
    color: $main;
  }
}

.tab-secondary {
  .mat-tab-labels {
    background-color: rgba(250, 250, 250, 1) !important;
  }

  .mat-tab-label-content {
    color: $text-dark !important;
  }
}

.tab-sm {
  .mat-tab-label {
    //height: 2.5rem !important;
  }

  .mat-tab-label-content {
    //padding: .17rem 1.25rem !important;
    //font-size: 0.9rem;
  }
}

.unscrollable-tab-content {
  display: flex !important;
  //padding: 10px;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  & > *:nth-child(2) {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 0rem;
  }

  .mat-tab-header {
    padding-bottom: 1.67rem;
  }

  .mat-tab-body-wrapper {
    max-height: 100%;
    overflow-y: scroll;
    flex: 1 1 100%;
    flex-direction: column;
  }
}

.auto-height-tab-content {
  @extend .unscrollable-tab-content;
  height: auto;
  max-height: max-content !important;
}

mat-tab-group.progress-tab-group {
  mat-tab-header {
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          position: relative;
          background-color: unset;

          .mat-tab-label {
            visibility: hidden;
            height: 2.9rem !important;
          }

          .mat-tab-label-content {
            width: 100%;
            height: 100%;
            background-color: unset;
            box-shadow: unset;
            font-size: 20px;
            font-weight: 500;
            color: $main-text;
            padding: 0 !important;
            margin: 0 !important;
            justify-content: start;
          }

          .mat-tab-label-active {
            visibility: unset;
            position: absolute;
            top: 0;
            bottom: 0;

            .mat-tab-label-content {
              padding: 0 !important;
              margin: 0 !important;
              justify-content: start;
            }
          }
        }
      }
    }
  }
}

mat-tab-group.progress-tab-group .mat-tab-body-wrapper {
  padding-top: 20px;
}

mat-tab-group.progress-tab-group mat-tab-header {
  position: sticky !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  padding-bottom: 10px;
  background-color: #fff !important;
}

mat-tab-group.header-removed {
  mat-tab-header {
    display: none;
  }

  .mat-tab-body-wrapper {
    padding-top: 0;
  }
}

app-edit-page {
  mat-tab-group {
    flex: 1;

    .mat-tab-header {
      padding-bottom: 1rem;
      border-bottom: 0.1rem solid $gray !important;
    }
  }
}
