@use '@angular/material' as mat;
@import '~quill/dist/quill.snow.css';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies();
@include mat.all-legacy-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();
@include mat.legacy-core();
@import 'theming/light-theme';
@import 'theming/black-theme';
//layout
@import 'layout/page';
@import 'layout/wrapper';
//abstract
//@import "abstracts/pallets";
// components
@import 'components/page';
@import 'components/tab';
@import 'components/table';
@import 'components/cards';
@import 'components/button';
@import 'components/common';
@import 'components/dialog';
@import 'components/select';
@import 'components/report';
@import 'components/dashboard';
@import 'components/menu';
@import 'components/mention';
//form-filters
@import 'components/form-fields';
@import 'components/form_dropdown';
// typography
@import 'abstracts/typography';
// sidebar
@import 'components/scrollbar';
//base
@import 'components/toggle';
// responsive
//@import "responsive/headers";
//@import "responsive/media_queries";
@import 'components/image';
@import 'components/link';
@import 'components/navbar';

@mixin assets-app-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  //button{
  //  background-color: mat.get-color-from-palette($primary, default-contrast);
  //}
}

@mixin custom-component-theme($theme) {
  //@include assets-app-component-theme($theme);
}
@include mat.all-component-themes($assets-light-theme);
@include mat.all-legacy-component-themes($assets-light-theme);
.light-theme {
  /* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
  @include mat.all-component-themes($assets-light-theme);
  @include mat.all-legacy-component-themes($assets-light-theme);
  @include assets-app-component-theme($assets-light-theme);
}

.dark-theme {
  /* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
  @include mat.all-component-themes($assets-black-theme);
  @include mat.all-legacy-component-themes($assets-black-theme);
  @include assets-app-component-theme($assets-black-theme);
}
