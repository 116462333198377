@import '../abstracts/variables';

app-page-host router-outlet.not-ready-router-outlet + * {
  display: none;
}

.content-location {
  display: flex;
  font-size: $text-md;
  gap: 0.75rem;
  margin-bottom: 1rem;

  .left-part {
    display: flex;
    flex-basis: 25%;
    white-space: nowrap;
    min-width: 25%;
    justify-content: flex-end;
    align-items: center;
    color: $text-light;
    text-align: right;
    margin: 0;
  }

  .right-part {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 0;
    gap: 0.2rem;
    overflow: hidden;
  }
}

.content-description {
  margin: 0 0 20px 0;
  font-size: $text-sm;
  font-style: italic;
  font-weight: 400;
  color: $text-light;
  word-wrap: break-word;
}
