@import '../abstracts/variables';

.text-long {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

mat-chip.mat-standard-chip {
  font-size: 11px;
  padding: 2px 12px;
  border-radius: 14px;
  line-height: normal;
  height: $chip-height;
  min-height: unset;
  font-weight: 600;
  border: solid 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.assets-chip-outline-bold {
    border: solid 0.2rem;
  }
}

.assets-chip-sm {
  height: 22px !important;
}

.assets-chip-success {
  background-color: $active-accent !important;
  color: $active !important;
}

.assets-chip-danger {
  background-color: $danger-accent !important;
  color: $danger !important;
}

.assets-chip-yellow {
  background-color: $yellow-accent !important;
  color: $yellow !important;
}

.assets-chip-pink {
  background-color: $pink !important;
  color: $pink-accent !important;
}

.assets-chip-primary {
  outline: 1px solid $dark-stroke !important;
  color: $main !important;
  background-color: #fff !important;
  box-sizing: border-box;

  .assets-chip-remove {
    color: $main !important;
    opacity: 1 !important;
  }
}

.component-wrapper-temp {
  border: 0.17rem dashed #b57af9;
  padding: 3.33rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
  gap: 1.25rem;
  background-color: #f1f1f1;
}

//DOM manipulations
.pointer-no-actions {
  pointer-events: none;
}

.progress-bar-bottom {
  position: absolute !important;
  left: 0;
  bottom: 0;
}

.progress-bar-top {
  position: absolute !important;
  left: 0;
  top: 0;
}

.assets-menu
  .mat-badge-small.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: -0.25rem !important;
  top: -0.25rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-underline {
  &:hover {
    text-decoration: underline;
  }
}

.disabled {
  opacity: 0.5;
  color: #ccc;
  pointer-events: none;
}

.locate-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(35, 49, 60, 1);
}

.module-active {
  color: $main;
  //border: 1px solid #007CD9;
  background-color: #c7e7f9;
}

.cdk-overlay-dark-backdrop {
  background: none;
}

.assets-h2,
.assets-headline-6,
.assets-typography h2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #23313c;
}

p {
  white-space: pre-line;
}

.h4 {
  margin: $button-md 0;
}

.text-break-word {
  overflow-wrap: break-word;
}

.app-link-directive {
  cursor: pointer;
}

.de-activate-dialog {
  h3 {
    margin-bottom: 0;
    font-weight: bold;
  }

  span {
    font-weight: normal;
  }

  ul {
    margin-left: 3rem;
  }
}

.app-success {
  color: $active !important;
}

.app-danger {
  color: $danger !important;
}

.app-yellow {
  color: $yellow !important;
}

.app-primary {
  color: $main !important;
}

.app-chip-gray {
  background-color: $gray;
}

.assets-flex-content {
  display: flex;
  align-items: center;

  &-center {
    @extend .assets-flex-content;
    justify-content: center;
  }

  &-end {
    @extend .assets-flex-content;
    justify-content: flex-end;
  }
}

.font-size-md {
  font-size: $text-md;
}

.mat-checkbox-layout {
  margin-bottom: 0 !important;
}

quill-view-html {
  .ql-editor {
    padding: 0;

    p {
      margin: 0;
    }
  }
}

.help-hint {
  width: auto !important;
  height: auto !important;
  font-size: 1.2rem !important;
  opacity: 0.4;
}
