@import 'abstracts/variables';

mat-drawer-container {
  mat-drawer-content {
    flex: 1 1 100%;
  }

  background-color: $bg-main !important;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  mat-drawer.right-drawer {
    width: 320px !important;
  }
  mat-drawer {
    background-color: $bg-main !important;
    //padding: 16px 0 16px 16px;
    border-right: 0 !important;
    height: 100%;
    width: $drawer-width;

    .sidenav {
      overflow: hidden;
      background-color: #fff !important;
      height: 100%;
      width: 100%;
      //border-radius: .67rem;
      border: 0.17rem solid rgba(246, 246, 246, 1);
      display: grid;
      color: rgba(73, 103, 126, 0.8);
      grid-template-rows: 8fr 1fr;

      header {
        color: $main;
        font-weight: bold;
      }

      main {
        overflow-y: scroll;
        max-width: 100%;

        .nav-list {
          padding: 0;
          margin: 0;

          .mat-list-item {
            height: 55px;
            color: rgba(73, 103, 126, 0.8);
            font-size: $text-md;
            font-weight: 600;
            margin-bottom: 0 !important;
            border-left: 3px solid transparent;

            &:hover {
              background-color: #f5f5f5;
            }

            &-active {
              color: $main;
              border-left: 3px solid $main;
              font-weight: bold;
              background-color: #f5f5f5;
            }
          }

          .mat-list-item-content {
            padding-right: 0;
          }

          .nav-item {
            height: 100%;
            position: relative;
            display: flex;
            margin-bottom: 0.4rem;
            width: 100%;
            align-items: center;
            justify-content: flex-start;

            p {
              margin-bottom: 0;
              margin-left: 0.83rem;
            }

            &-expansion-panel {
              padding: 0;
              box-shadow: none !important;

              mat-expansion-panel {
                border-radius: 0 !important;
                min-height: 55px;
                margin-bottom: 5px;
              }

              mat-expansion-panel mat-list-item {
                border-left: 3px solid #d7d7d7;
              }

              mat-expansion-panel .mat-list-item-active {
                border-left: 3px solid $main !important;
              }

              .mat-expansion-panel-header {
                padding-left: 0 !important;
                color: #fff;
                height: 100%;
                width: 100%;

                .mat-expansion-panel-header-title {
                  margin-right: 0 !important;
                }

                .expansion-header {
                  &:hover {
                    background-color: unset;
                  }
                }
              }

              .mat-expansion-panel-body {
                padding: 0 !important;
              }

              mat-expansion-panel:not([class*='mat-elevation-z']) {
                box-shadow: none !important;
                //border-left: 3px solid #969696;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  mat-drawer-container {
    mat-drawer {
      width: 180px;
    }

    mat-drawer > .sidenav > header {
      font-size: $text-sm;
    }

    .nav-item {
      font-size: $text-sm;
    }
  }

  main .mat-list .mat-list-item {
    height: 40px !important;
  }
}

mat-expansion-panel .nav-item {
  &:hover {
    background-color: unset !important;
  }
}

@media screen and (min-width: 1900px) {
  mat-drawer-container {
    mat-drawer {
      width: 288px;
    }

    mat-drawer > .sidenav > header {
      font-size: $text-lg;
    }

    .nav-item {
      font-size: $text-lg;
    }
  }
  main .mat-list .mat-list-item {
    height: 50px !important;
  }
}

mat-progress-bar.nav-progress-bar {
  position: absolute;
}
