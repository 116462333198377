@import '../abstracts/variables';

.assets-form-field {
  //border: 1px solid red;

  mat-form-field {
    width: 100%;
  }

  .mat-form-field-outline {
    top: 0 !important;
  }

  &-label {
    display: block;
    font-size: 15px;
    font-weight: normal;
    color: $text-dark;
  }

  &.label-top {
    .assets-form-field-label {
      display: block;
      margin-bottom: 4px;
    }
  }

  &.label-left {
    display: flex;
    align-items: center;

    .assets-form-field-label {
      display: block;
      margin-right: 6px;
      margin-top: -12px;
    }
  }

  .mat-form-field-wrapper {
    margin: 0 !important;
  }
}

.assets-form-field.assets-filters.label-left {
  display: flex;
  align-items: baseline;
}

.assets-filters {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.assets-form-field-without-label {
  .assets-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}

.assets-form-group {
  outline: 0.15rem dashed $form-group-border;
  padding: 1rem;
  margin-bottom: 1.5rem;
  &:hover {
    outline: 0.15rem dashed $form-group-border-hover;
  }
}

.assets-form-page-grid {
  padding: 1rem;
  min-height: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 3rem;
  width: 100%;
}

assets-rich-text {
  .ql-container {
    min-height: 10rem;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .ql-editor {
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }
}
