@import '../abstracts/variables';

.text-body-dark {
  color: $text-dark;
}

.text {
  &-xs {
    font-size: 10px;
  }

  &-sm {
    font-size: 12px;
  }

  &-md {
    font-size: 14px;
  }

  &-lg {
    font-size: 16px;
  }

  &-xl {
    font-size: 20px;
  }

  &-xxl {
    font-size: 24px;
  }
}

//colors
.text {
  &-main {
    color: $main-text;
  }
}

.main-text {
  color: $main;
}
