img {
  &.image-size {
    &-large {
      width: 200px;
      height: 200px;
      object-fit: cover;
    }

    &-medium {
      width: 80px;
      height: 80px;
    }

    &-small {
      width: 40px;
      height: 40px;
    }

    &-extra-small {
      width: 30px;
      height: 30px;
    }
  }
}
