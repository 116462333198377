/**
color variables
 */
$main: rgba(0, 124, 217, 1);
$main-light: rgba(0, 124, 217, 0.04);
$main-accent: rgba(0, 124, 217, 0.16);
$main-deep-1: rgba(0, 96, 168, 1);
$main-deep-2: rgba(0, 67, 117, 1);
$bg: rgba(247, 247, 247, 1);
$bg-main: #fafafa;
$gray: rgba(224, 224, 224, 1);
$gray-dark: rgba(134, 134, 134, 1);
$main-text: rgba(35, 49, 60, 1);
$text-dark: rgba(54, 76, 93, 1);
$text-light: rgba(174, 174, 174, 1);
$text-gray: rgba(117, 126, 132, 1);
$active: rgba(57, 148, 114, 1);
$danger: rgba(169, 57, 57, 1);
$danger-deep-1: rgb(154, 35, 35);
$active-accent: rgba(239, 251, 246, 1);
$danger-accent: rgba(255, 238, 238, 1);
$yellow: rgba(254, 200, 5, 1);
$orange: rgba(241, 148, 42, 1);
$yellow-accent: rgba(255, 255, 240, 1);
$pink: rgba(239, 207, 227, 1);
$pink-accent: rgba(227, 115, 131, 1);
$table-border: rgba(246, 246, 246, 1);
$form-field-bg-color: #fafafa;
$form-field-placeholder-color: #aeaeae;
//borders
$form-group-border: $gray;
$form-group-border-hover: $gray-dark;
$form-field-border-color: rgba(0, 0, 0, 0.12);
$dark-stroke: rgba(242, 242, 242, 1);
$dark-border: rgb(167, 167, 167);
$form-field-border-radius: 6px;
$table-border: 1px solid rgb(93 102 109 / 41%);
//cards
$card-bg: #fff;
//forms
$input-bg: #fff;
//btns
$btn-disabled: rgba(171, 174, 176, 1);
//table
$table-bg: #f2f2f2;
$table-bg-yellow: #fdfdc0ff;
$table-bg-gray: #e9e9e9;

// borders
$border-rd-sm: 8px;
$border-rd-md: 0.67rem;
$border-rd-lg: 0.83rem;
$border-rd-xl: 1rem;
//button height
$button-xs: 24px;
$button-sm: 3.3333rem;
$button-md: 4rem;

// font size;
$text-xs: 10px;
$text-sm: 12px;
$text-md: 14px;
$text-lg: 16px;
$text-xl: 18px;
$text-xxl: 20px;
//icons
$icon-sm-size: 1.17rem;
$icon-sm-size-width: 1.17rem;
$icon-md-size: 1.67rem;
$icon-md-size-width: 1.67rem;
//button icons
$icon-btn-size: 1rem;
$icon-btn-sm-size: 1rem;
//$icon-btn:1rem;

$chip-height: 27px;
$header-height: 41px;
$permissions-container-height: 28vh;
$drawer-width: 224px;
