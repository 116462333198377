@import '../abstracts/variables';

$br-color: rgb(235, 235, 235);
$border: 1px solid rgb(93 102 109 / 41%) !important;

mat-row {
  min-height: 42px !important;
}

.assets-table-full-width {

  mat-table {
    width: max-content !important;
    min-width: fill-available !important;
  }
}

.table {
  &-wrapper {
    display: flex;
    flex-direction: column;
    font-size: $text-sm;
    height: auto;
    position: relative;
    overflow-y: scroll;
    flex: revert;

    .mat-column-select {
      flex: 0 0 4.5%;
    }

    mat-table {
      background-color: inherit !important;
      width: 100%;

      &:not(.not-striped-table) {
        mat-row:nth-child(odd) {
          background-color: rgba(242, 242, 242, 1);
          border: none;
        }
      }

      mat-header-row {
        border-bottom: $border;
        border-bottom-width: 2px !important;
        background-color: $bg-main !important;
        z-index: 99999;
        min-height: 40px !important;

        mat-header-cell {
          font-weight: 700;
          color: $text-gray;
          font-size: 12px;

          .mat-sort-header-content {
            text-align: start;
          }
        }
      }

      mat-row {
        background-color: #fff;

        &.yellow {
          background-color: $table-bg-yellow;
        }

        &.gray {
          background-color: $table-bg-gray;
        }

        &:hover {
          background-color: rgb(226, 240, 251) !important;
          border-color: rgb(226, 240, 251) !important;
        }

        mat-cell {
          min-height: 42px;
          font-weight: 400;
          font-size: $text-sm;
          color: $text-dark !important;
        }
      }

      mat-footer-row {
        border-top: $table-border;
        background-color: $card-bg !important;

        mat-footer-cell {
          min-height: 2.6rem;
          font-weight: 400;
          font-size: $text-sm;
          color: $text-dark;
        }
      }

      .mat-row,
      .mat-header-row,
      .mat-footer-row {
        gap: 0.2rem;
        align-items: stretch;

      .mat-table-sticky-border-elem-right {
        border-left: $border;
      }
      .mat-table-sticky-border-elem-left {
        border-right: $border;
      }
      }
    }
  }

  &__pagination {
    display: flex;
    background-color: inherit;
    flex-direction: column;

    mat-paginator {
      margin-top: auto;
      font-size: $text-md !important;
      background-color: inherit !important;
      max-height: 2.92rem !important;

      .mat-paginator-outer-container {
        max-height: 2.92rem !important;

        .mat-paginator-container {
          max-height: 2.92rem !important;
          min-height: 2.92rem !important;

          & > * {
            max-height: 2.92rem !important;
          }

          .mat-paginator-page-size {
            margin-right: auto;
            align-items: center !important;

            .mat-form-field-underline {
              display: none;
            }
          }

          .mat-paginator-range-actions {
            align-items: center !important;

            .mat-paginator-range-label {
              margin-top: 0.42rem;
            }
          }
        }
      }

      .mat-paginator-icon {
        width: 1.67rem !important;
        height: 1.67rem !important;
      }
    }
  }
}

.employees-container {
  .mat-column-select {
    flex: 0 0 4%;
  }

  .mat-column-name {
    flex: 1 0 10%;
    display: grid;

    .employee-name {
      height: 44px;
      vertical-align: middle;

      h5 {
        font-size: $text-md;
      }
    }
  }

  .mat-column-level {
    flex: 1 0 6%;
  }

  .mat-column-actions {
    flex: 0 0 4%;
  }

  .mat-column-positions {
    flex: 1 0 6%;
  }
}

/**
*Custom references-table flex adjustment
*/
.equipments-container {
  .mat-column-owner {
    padding-left: 5px;
  }

  .mat-column-description {
    flex: 1 0 20%;
    display: grid;
  }
}

.permissions-container {
  .mat-column-action {
    flex: 1 1 75%;
    display: grid;
  }
}

.users-container {
  .mat-column-employee {
    flex: 1 0 14%;
  }

  .mat-column-userName {
    flex: 1 0 14%;
  }

  .mat-column-actions {
    flex: 0 0 5%;
  }
}

.table-white {
  mat-header-row {
    background-color: #fff !important;
  }
}

.table-gray {
  mat-header-row {
    background-color: $bg-main !important;
  }
}

mat-cell {
  .assets-form-field {
    .mat-form-field-wrapper {
      padding: 0;
    }
  }

  .assets-form .mat-form-field-infix {
    height: 3.2rem;
    padding-top: 0;
  }
}

@media screen and (max-width: 1400px) {
  .table {
    &-wrapper {
      mat-table {
        mat-row {
          background-color: #fff;
          border: none;

          mat-cell {
            height: 42px !important;
          }
        }

        mat-header-row {
          mat-header-cell {
            font-size: 12px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            color: #757e84 !important;
          }
        }

        .mat-column-select {
          flex: 0 0 5%;
        }
      }
    }
  }

  .employees-container {
    .mat-column-name {
      .employee-name {
        height: 40px;
        vertical-align: middle;

        h5 {
          font-size: $text-sm;
        }
      }
    }
  }
}

mat-table {
  .hidden-row {
    display: none;
  }
}

.mat-table {
  &.resizing {
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: col-resize;
  }
  .mat-cell {
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

   .cdk-drag:not(.cdk-drag-disabled) {
    cursor: move;

    .mat-sort-header-container {
      cursor: pointer;
    }
  }

  .mat-header-cell {
    position: relative;

    .resize-holder {
      display: none;
      position: absolute;
    }

    &:hover {
      .resize-holder {
        display: block;
      }
    }

    &:not(:last-child) {
      .resize-holder {
        cursor: col-resize;
        width: 3px;
        height: 40%;
        right: 5px;
        margin-left: auto;
        background-color: rgba(0, 0, 0, 0.22);

        &:hover {
          width: 4px;
          transition-delay:0;
        }
      }
    }
  }
}
