@import '../abstracts/variables';

.simple-select-panel {
  &__chip {
    mat-chip {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    mat-option.mat-selected {
      app-status-chip {
        mat-chip {
          background-color: $bg-main;
        }
      }
    }
  }
}

app-simple-select {
  .mat-select {
    display: flex;

    .mat-select-trigger {
      height: auto;
    }
  }
}
