@import '../abstracts/variables';

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(15px, 0, 0) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $main !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  border: 2px solid $main;
}

.mat-slide-toggle-bar {
  border: 2px solid $text_dark;
  height: 17px !important;
  width: 29px !important;
  background-color: #fff !important;

  .mat-slide-toggle-thumb-container {
    height: 10px;
    width: 10px;
    top: 3px !important;
    left: 1px;

    .mat-ripple {
      display: none;
    }

    .mat-slide-toggle-thumb {
      height: 7px;
      width: 7px;
      background-color: $text_dark;
    }
  }
}
