/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: lato;
  src: url(assets/fonts/inter/inter/Inter-VariableFont_slnt,wght.ttf)
    format('opentype');
}

html,
body {
  height: 100%;
  font-family: 'Inter', sans-serif;
}

html {
  font-size: 12px;
}

body {
  margin: 0;
  background-color: #fafafa;
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}
