@import '../abstracts/variables';

.assets-form-dropdown {
  position: relative;
  //test
  //border:1px solid red;

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-flex {
    padding: 1.1em 0.75em 0 0.6em !important;
    margin-top: -0.25em;
    border: 1px solid $form-field-border-color;
    height: 48px;
    background-color: $card-bg !important;
    border-radius: $form-field-border-radius !important;

    &:hover {
      outline: 2px solid black;
      outline-offset: -1px;
    }

    .mat-form-field-outline {
      height: 100% !important;
    }

    .mat-form-field-infix {
      border: 1px solid transparent;
      padding: 0 !important;
      align-self: stretch;
      font-size: $text-md;

      mat-select {
      }

      .mat-form-field-label-wrapper {
        position: static !important;

        label.mat-empty {
          display: unset;
          margin-top: -12px;
        }

        label {
          display: none;
          font-size: $text-md;
          font-weight: 400;
          color: $form-field-placeholder-color;
        }
      }
    }
  }

  .mat-select-arrow-wrapper {
    visibility: hidden;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-label-wrapper label,
  .mat-form-field.mat-form-field-invalid .mat-form-field-suffix mat-icon {
    color: $danger;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-flex {
    outline: 2px solid $danger;
    outline-offset: -1px;
  }
}
