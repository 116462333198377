@import '../abstracts/variables';

.dashboard-card {
  width: 100%;
  background-color: rgba(229, 229, 229, 1);
  padding: 1rem;
  margin-bottom: 1.25rem;
  border-radius: 0.25rem;

  .dashboard-card-header {
    color: $main-deep-1;
    font-size: $text-xl;
    font-weight: 600;
    margin-bottom: 0.6rem;

    &-column-name {
      text-align: end;
      font-style: italic;
    }
  }

  .dashboard-card-content__item:not(:first-child) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.25rem;
  }

  div {
    font-size: $text-lg;
  }
}
