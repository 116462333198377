@import '../abstracts/variables';

.assets-navbar {
  height: 4rem;
  max-height: $header-height;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  padding: 0 10px;
  background-color: #fff;
}
